<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="19">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>{{ this.direcParam.directName }}</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
      <el-col :span="5">
        <div>
          <el-input placeholder="请输入搜索内容" v-model.trim="tableParam.key" style="width:100%" @change="tableChangeSearchKey">
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </div>
      </el-col>
    </el-row>
    <el-table ref="elecInpourTableRef" :key="tableParam.tableKey" :border="true" height="666" :cell-style="{height:'44px',padding:0,margin:0}" :data="tableData"
      tooltip-effect="dark" style="width: 100%" :highlight-current-row="true" @selection-change="tableSelectionChange" @row-click="tableRowClick">
      <el-table-column header-align="center" type="selection" width="40"></el-table-column>
      <el-table-column header-align="center" type="index" width="40"></el-table-column>
      <el-table-column header-align="center" label="用户手机" prop="userMobile" width="110">
      </el-table-column>
      <el-table-column header-align="center" label="微信用户ID" prop="wxOpenId" show-overflow-tooltip>

      </el-table-column>
      <el-table-column header-align="center" label="订单号" prop="outTradeNo" width="280">
        <template slot-scope="scope">
          <span style="font-family:monospace;font-size:14px;font-weight: bold;">{{scope.row.outTradeNo}}</span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" label="微信订单号" prop="transactionId" width="250">
        <template slot-scope="scope">
          <el-tooltip placement="top">
            <div slot="content" style="line-height:20px;">
              微信用户ID:{{scope.row.wxOpenId}}<br />
            </div>
            <span style="font-family: monospace;font-size:14px;font-weight: bold;">{{scope.row.transactionId}}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column header-align="center" label="退款流水号" prop="outRefundNo" width="250">
        <template slot-scope="scope">
          <span style="font-family:monospace;font-size:14px;font-weight: bold;">{{scope.row.outRefundNo}}</span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" label="金额-分" prop="totalFee" width="100"></el-table-column>

      <!-- <el-table-column header-align="center" label="类型:0 充值,1预充值,2预充值退款,3退款" prop="inpourType"></el-table-column> -->
      <el-table-column header-align="center" label="类型" prop="inpourTitle" width="170">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.inpourType==0">{{scope.row.statusTitle}}</el-tag>
          <el-tag v-else-if="scope.row.inpourType==1" type='success'>{{scope.row.inpourTitle}}</el-tag>
          <el-tag v-else-if="scope.row.inpourType==2" type='danger'>{{scope.row.inpourTitle}}</el-tag>
          <el-tag v-else-if="scope.row.inpourType==3" type='warning'>{{scope.row.inpourTitle}}</el-tag>
          <el-tag v-else-if="scope.row.inpourType==4" type='warning'>{{scope.row.inpourTitle}}</el-tag>
          <el-tag v-else-if="scope.row.inpourType==5" type='danger'>{{scope.row.inpourTitle}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column header-align="center" label="发生时间" prop="createTime" width="140"></el-table-column>

    </el-table>
    <el-pagination @size-change="tableSizeChange" @current-change="tableCurrentChange" :current-page="tableParam.page"
      :page-sizes="[30,40, 50, 60,70,80,90,100]" :page-size="tableParam.limit" layout="total, sizes, prev, pager, next, jumper" :total="tableParam.count">
    </el-pagination>

    <!--编辑的表单-->

    <el-dialog v-dialogDrag :title="formDialogParam.title" :visible.sync="formDialogParam.visible" :width="formDialogParam.width" :top="formDialogParam.top"
      :close-on-click-modal='false'>
      <el-form ref="elecInpourFormRef" :model="formData" :rules="formDialogRules" label-width="80px" size="small"><!-- `prop` 为绑定的规则 -->
        <el-form-item label="用户ID" prop="userId">
          <el-input-number id="userId" v-model="formData.userId" controls-position="right" :min="0" :max="2147483647" placeholder="请输入用户ID"></el-input-number>
        </el-form-item>

        <el-form-item label="用户手机" prop="userMobile">
          <el-input id="userMobile" v-model="formData.userMobile" placeholder="请输入用户手机"></el-input>
        </el-form-item>

        <el-form-item label="微信用户ID" prop="wxOpenId">
          <el-input id="wxOpenId" v-model="formData.wxOpenId" placeholder="请输入微信用户ID"></el-input>
        </el-form-item>

        <el-form-item label="微信订单号" prop="transactionId">
          <el-input id="transactionId" v-model="formData.transactionId" placeholder="请输入微信订单号"></el-input>
        </el-form-item>

        <el-form-item label="交易的金额(分)" prop="totalFee">
          <el-input-number id="totalFee" v-model="formData.totalFee" controls-position="right" :min="0" :max="2147483647"
            placeholder="请输入交易的金额(分)"></el-input-number>
        </el-form-item>

        <el-form-item label="(商户)订单流水号" prop="outTradeNo">
          <el-input id="outTradeNo" v-model="formData.outTradeNo" placeholder="请输入(商户)订单流水号"></el-input>
        </el-form-item>

        <el-form-item label="(商户)退款流水号" prop="outRefundNo">
          <el-input id="outRefundNo" v-model="formData.outRefundNo" placeholder="请输入(商户)退款流水号"></el-input>
        </el-form-item>

        <el-form-item label="类型:0 充值,1预充值,2预充值退款,3退款" prop="inpourType">
          <el-input-number id="inpourType" v-model="formData.inpourType" controls-position="right" :min="0" :max="2147483647"
            placeholder="请输入类型:0 充值,1预充值,2预充值退款,3退款"></el-input-number>
        </el-form-item>

        <el-form-item label="类型描述" prop="inpourTitle">
          <el-input id="inpourTitle" v-model="formData.inpourTitle" placeholder="请输入类型描述"></el-input>
        </el-form-item>

        <el-form-item label="创建时间" prop="createTime">
          <el-date-picker id="createTime" v-model="formData.createTime" type="datetime" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm"
            placeholder="请选择创建时间"></el-date-picker>
        </el-form-item>

      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="formDialogParam.visible = false">取 消</el-button>
        <el-button type="primary" @click="formDataSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog v-dialogDrag :title="formChooseDialogParam.title" :visible.sync="formChooseDialogParam.visible" width="50%" :close-on-click-modal='false'>
      <el-table :data="tableMultipleSelection" style="width: 100%;" height="300">
        <el-table-column header-align="center" type="index" width="40"></el-table-column>
        <el-table-column prop="title" label="选定的记录">
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="formChooseDialogParam.visible = false">取 消</el-button>
        <el-button v-if="formChooseDialogParam.action1" type="danger" @click="formChooseSubmit(false)">{{formChooseDialogParam.action1View}}</el-button>
        <el-button v-if="formChooseDialogParam.action2" type="primary" @click="formChooseSubmit(true)">{{formChooseDialogParam.action2View}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<style lang="less" scoped>
@import "@/assets/css/TableCus.css";
</style>
<script>

import store from '@/store';
import { getTableData, doNew, doModify, doDelete, } from '@/api/biz/ElecInpour'; //数据源,如果没有上传文件的部分,请删除postFile, postImg,不删除也不影响
import { required, phone, len, email } from "@/utils/formRules" //校验规则,如有特殊要求请自己去修改填写,请删除不使用的,但不删除也不影响
export default {
  components: {},
  data () {
    return {
      /*导航栏状态 */
      direcParam: {
        'directName': '名称名称名称名称名称名称名称名称名称ElecInpour'
      },
      /*表格参数 */
      tableParam: {
        'page': 1,
        'limit': 40,
        'count': 0,
        'key': '',
        'tableKey': 0,
        'searchType': 'normal'
      },//表格的传参
      /*表格数据 */
      tableData: [],
      /*表格选中的数据 */
      tableMultipleSelection: [], //多选的数据
      /*表单的参数 */
      formDialogParam: //form弹出窗口的显示状态
      {
        visible: false,
        title: '编辑',
        formDataAction: '',
        width: '50%',
        top: '5vh',
      },
      formChooseDialogParam: //form发布弹出窗口的显示状态
      {
        formChooseAction: '',
        title: '',
        visible: false,
        action1: false,
        action1View: '',
        action2: false,
        action2View: ''
      },
      /*原始数据 */
      formRawData: {}, //由于不能直接清空所有的数据回到初使状态(表格参数据库和搜索数据不能清空),所以建立,在mount的时候作一个深拷贝,将formData的数据初使化的原始数据复制一份过来
      /*初使化数据 */
      formData: {
        userId: 0,
        userMobile: '',
        wxOpenId: '',
        transactionId: '',
        totalFee: 0,
        outTradeNo: '',
        outRefundNo: '',
        inpourType: 0,
        inpourTitle: '',
        createTime: '',

      },
      formDialogRules:
      {
        userId: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        userMobile: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        wxOpenId: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        transactionId: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        totalFee: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        outTradeNo: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        outRefundNo: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        inpourType: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        inpourTitle: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        createTime: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增

        // title: [required,len(0,255)],多规则绑定
        // mail: [required,len(0,255), email],多规则绑定
        // authorWriter: [required],
      },
    }
  },
  methods: {

    /*-------------------------------------------------表单(form)事件 -----------------------------------------------*/
    /*表单(form)-新建 */
    formDataNew () {
      this.formData = JSON.parse(JSON.stringify(this.formRawData)); //深拷贝一份原始数据到formData,就相当于重置表单了
      this.formDialogParam.title = '新建';
      this.formDialogParam.formDataAction = 'new';
      this.formDialogParam.visible = true;
    },
    /*表单(form)-修改 */
    formDataModify () {
      if (this.tableMultipleSelection.length != 1) {
        this.notifyInfo("您只能选择一条记录,才能修改");
      }
      else {
        this.formData = JSON.parse(JSON.stringify(this.tableMultipleSelection[0])); //深COPY主要是为了不想改了,就渲染到table上造成不必要的麻烦;
        this.formDialogParam.formDataAction = 'modify';
        this.formDialogParam.title = '修改';
        this.formDialogParam.visible = true;
      }

    },
    /*表单(form)-删除 */
    formDataDelete () {
      if (this.tableMultipleSelection.length < 1) {
        this.notifyInfo("您必须最少选择一条记录,才能删除");
        return;
      }
      else {
        this.formChooseDialogParam.title = "是否真的要删除选定的记录(已发布的不删除)"
        this.formChooseDialogParam.formChooseAction = 'delete';
        this.formChooseDialogParam.action1 = true;
        this.formChooseDialogParam.action1View = '删 除';
        this.formChooseDialogParam.action2 = false;
        this.formChooseDialogParam.action2View = '';
        this.formChooseDialogParam.visible = true;
      }
    },
    formDataValidate () {
      let result = true;
      this.$refs.elecInpourFormRef.validate((valid) => {
        result = valid;
      });
      return result;
    },
    /*表单(form)-提交 */
    formDataSubmit () {
      if (!this.formDataValidate()) return;
      this.formDialogParam.visible = false;
      //如有特殊的Json要求,请深Copy一份,再进行修改例如
      const postData = JSON.parse(JSON.stringify(this.formData));

      //postData.classId = this.tableParam.classId;
      //postData.imageUrl = JSON.stringify(this.formData.imageUrl);//由于MysqlJson的原因
      //postData.addFile = JSON.stringify(this.formData.addFile);//由于MysqlJson的原因
      //doNew(this.formData).then(response => {
      if (this.formDialogParam.formDataAction == 'new') {
        doNew(postData).then(response => {
          if (response.data.success) {
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
          }
        })
      }
      else if (this.formDialogParam.formDataAction == 'modify') {
        doModify(postData).then(response => {
          if (response.data.success) {
            this.tableParam.key = '';
            this.tableParam.page = 1;
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
            this.tableDateRefresh();
          }
        })
      }
    },
    formChooseSubmit () {
      this.formChooseDialogParam.visible = false;
      if (this.formChooseDialogParam.formChooseAction == 'delete') {
        const selectNum = this.tableMultipleSelection.length;
        let canId = [];
        for (let i = 0; i < selectNum; i++) {
          if (!this.tableMultipleSelection[i].isPublic) {
            canId.push(this.tableMultipleSelection[i].id);
          }
        }
        const data = new FormData()
        data.append('id', canId);
        doDelete(data).then(response => {
          if (response.data.success) {
            this.tableParam.key = '';
            this.tableParam.page = 1;
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
            this.tableDateRefresh();
          }
        })
      }
    },
    /*-------------------------------------------------表格事件 -----------------------------------------------*/
    //表格-多选事件,当checkBox被选中,改变已选的值
    tableSelectionChange (val) {
      this.tableMultipleSelection = val;
    }
    ,
    /*表格-行选,点击事件 */
    tableRowClick (row) {
      //this.formData = row;
      this.formData = JSON.parse(JSON.stringify(row));//主要是为了不想改了就生效到table上
      console.log(row)
      this.$refs.elecInpourTableRef.clearSelection(); //清除选中
      this.$refs.elecInpourTableRef.toggleRowSelection(row); //设定选中
    },
    /*表格-改变每页显示多少条,直接重置到第一页*/
    tableSizeChange (val) {
      if (val != this.tableParam.limit) {
        this.tableParam.limit = val
        this.tableParam.page = 1;
        this.tableDateRefresh();
      }
    },
    /*表格-改变第几页*/
    tableCurrentChange (val) {
      if (val != this.tableParam.page) {
        this.tableParam.page = val;
        this.tableDateRefresh();
      }

    },
    /*表格-搜索关键词,由于改变才会重搜,所以不用在意 */
    tableChangeSearchKey (searchKey) {
      this.tableParam.key = searchKey;
      this.tableParam.page = 1;
      this.tableDateRefresh();

    },
    /*表格-置亮搜索关键词 */
    tableLightByKey (val) {
      // 不区分大小写  const Reg = new RegExp(searchData, 'i');
      // 全局替换  const Reg = new RegExp(searchData, 'g');
      if (this.tableParam.key) {
        const Reg = new RegExp(this.tableParam.key, 'ig');
        if (val) {
          // 注意 这里推荐使用正则占位符$& 不使用${searchData}  因为当这里使用正则表达式（i）不区分大小写时，如果你的文本是大写，搜索的关键字是小写，匹配结果会替换掉大写的文本
          // const res = val.replace(Reg, `<span style="background-color: yellow;color:#ffffff">${searchData}</span>`);
          const res = val.replace(Reg, `<span style="background-color: #50bfff;color:#ffffff">$&</span>`);
          return res;
        }
      }
      else {
        return val;
      }
    },
    /*表格-数据重置 */
    tableDateRefresh () {
      getTableData(this.tableParam).then((jsonData) => {
        this.tableData = jsonData.data.data;
        this.tableParam.count = jsonData.data.count;
        this.tableParam.page = jsonData.data.page;
        this.tableParam.limit = jsonData.data.limit;
      })
    },

  },
  /*监听Id的变化,如果变化了就重新去取数据 */
  watch: {},
  filters: {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.direcParam = JSON.parse(store.getters.getActiveCus());
    this.formRawData = JSON.parse(JSON.stringify(this.formData)); //深拷贝一份原始数据到formRawData
    this.tableDateRefresh();
  }
}
</script>
