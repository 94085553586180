import { httpForm, httpJson } from '@/utils/adminRequest'
//定义一个Info接口,数据表接口
export const getTableData = (tableParam) => {
    return httpJson.get('/info/list', { 'params': tableParam })
}
export const getInfo = (param) => {
    return httpJson.get('/info/info', { 'params': param })
}
export const doNew = (formData) => {
    return httpForm.post('/info/new', formData)
}
export const doModify = (formData) => {
    return httpForm.post('/info/modify', formData)
}
export const doDelete = (formData) => {
    return httpForm.post('/info/delete', formData)
}
export const sendStart = (formData) => {
    return httpForm.post('/info/sendStart', formData)
}
export const sendStop = (formData) => {
    return httpForm.post('/info/sendStop', formData)
}
export const sendRestart = (formData) => {
    return httpForm.post('/info/sendRestart', formData)
}

export const initDefault = (formData) => {
    return httpForm.post('/info/initDefault', formData)
}
export const searchParam = (formData) => {
    return httpForm.post('/info/searchParam', formData)
}
export const locationUpdate = (formData) => {
    return httpForm.post('/info/locationUpdate', formData)
}







