<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="19">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>{{ this.direcParam.directName }}</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
      <el-col :span="5">
        <div>
          <el-input placeholder="请输入搜索内容" v-model.trim="tableParam.key" style="width:100%" @change="tableChangeSearchKey">
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-button-group>
          <!-- <el-button @click="formDataNew" type="primary">新建</el-button>
          <el-button @click="formDataModify" type="primary">修改</el-button> -->
          <el-button @click="formDataDelete" type="primary">删除</el-button>
          <el-button @click="formDataReset" type="danger">清空</el-button>
        </el-button-group>
      </el-col>
    </el-row>
    <el-table ref="logsSubTableRef" :key="tableParam.tableKey" :border="true" height="666" :cell-style="{height:'44px',padding:0,margin:0}" :data="tableData"
      tooltip-effect="dark" style="width: 100%" :highlight-current-row="true" @selection-change="tableSelectionChange" @row-click="tableRowClick">
      <el-table-column header-align="center" type="selection" width="40"></el-table-column>
      <el-table-column header-align="center" type="index" width="40"></el-table-column>
      <el-table-column header-align="center" label="设备编号" prop="devNo" width="120">
        <template slot-scope="scope">
          <span v-html="tableLightByKey(scope.row.devNo)"></span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" label="订单号" prop="orderCode" show-overflow-tooltip>
        <template slot-scope="scope">
          <span style="font-family: 'Consolas';" v-html="tableLightByKey(scope.row.orderCode)"></span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" label="流水号" prop="waterNum" width="120">
        <template slot-scope="scope">
          <span v-html="tableLightByKey(scope.row.waterNum)"></span>
        </template>
      </el-table-column>

      <el-table-column header-align="center" label="插座" prop="place" width="60"></el-table-column>
      <el-table-column header-align="center" label="类型-事件描述" prop="logTitle" width="200">
        <template slot-scope="scope">
          <span>{{ scope.row.logType}}-{{ scope.row.logTitle }}</span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" label="数值" prop="logValue" width="60">
        <template slot-scope="scope">
          <span v-if="scope.row.logValue">{{scope.row.logValue}}{{scope.row.logValueUnit}}</span>
        </template>

      </el-table-column>
      <el-table-column header-align="center" label="发生时间" prop="logTime" width="160"></el-table-column>

    </el-table>
    <el-pagination @size-change="tableSizeChange" @current-change="tableCurrentChange" :current-page="tableParam.page"
      :page-sizes="[30,40, 50, 60,70,80,90,100]" :page-size="tableParam.limit" layout="total, sizes, prev, pager, next, jumper" :total="tableParam.count">
    </el-pagination>

    <!--编辑的表单-->

    <el-dialog v-dialogDrag :title="formDialogParam.title" :visible.sync="formDialogParam.visible" :width="formDialogParam.width" :top="formDialogParam.top"
      :close-on-click-modal='false'>
      <el-form ref="logsSubFormRef" :model="formData" :rules="formDialogRules" label-width="80px" size="small"><!-- `prop` 为绑定的规则 -->
        <el-form-item label="设备编号" prop="devNo">
          <el-input id="devNo" v-model="formData.devNo" placeholder="请输入设备编号"></el-input>
        </el-form-item>

        <el-form-item label="流水号" prop="waterNum">
          <el-input id="waterNum" v-model="formData.waterNum" placeholder="请输入流水号"></el-input>
        </el-form-item>

        <el-form-item label="订单号" prop="orderCode">
          <el-input id="orderCode" v-model="formData.orderCode" placeholder="请输入订单号"></el-input>
        </el-form-item>

        <el-form-item label="席位号" prop="place">
          <el-input-number id="place" v-model="formData.place" controls-position="right" :min="0" :max="2147483647" placeholder="请输入席位号"></el-input-number>
        </el-form-item>

        <el-form-item label="类型:0开始,256结束,2功率上报,3电量上报,4断电," prop="logType">
          <el-input-number id="logType" v-model="formData.logType" controls-position="right" :min="0" :max="2147483647"
            placeholder="请输入类型:0开始,256结束,2功率上报,3电量上报,4断电,"></el-input-number>
        </el-form-item>

        <el-form-item label="事件类型标题" prop="logTitle">
          <el-input id="logTitle" v-model="formData.logTitle" placeholder="请输入事件类型标题"></el-input>
        </el-form-item>

        <el-form-item label="数值" prop="logValue">
          <el-input-number id="logValue" v-model="formData.logValue" controls-position="right" :min="0" :max="2147483647" placeholder="请输入数值"></el-input-number>
        </el-form-item>

        <el-form-item label="数值单位" prop="logValueUnit">
          <el-input id="logValueUnit" v-model="formData.logValueUnit" placeholder="请输入数值单位"></el-input>
        </el-form-item>

        <el-form-item label="发生时间" prop="logTime">
          <el-date-picker id="logTime" v-model="formData.logTime" type="datetime" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm"
            placeholder="请选择发生时间"></el-date-picker>
        </el-form-item>

      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="formDialogParam.visible = false">取 消</el-button>
        <el-button type="primary" @click="formDataSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog v-dialogDrag :title="formChooseDialogParam.title" :visible.sync="formChooseDialogParam.visible" width="50%" :close-on-click-modal='false'>
      <el-table :data="tableMultipleSelection" style="width: 100%;" height="300">
        <el-table-column header-align="center" type="index" width="40"></el-table-column>
        <el-table-column prop="id" label="ID" width="80">
        </el-table-column>
        <el-table-column prop="orderCode" label="订单编号">
          <template slot-scope="scope">
            <span style="font-family: 'Consolas';font-size: 12px;">{{ scope.row.orderCode}}</span>
          </template>
        </el-table-column>
        <el-table-column header-align="center" label="插座" prop="place" width="60"></el-table-column>
        <el-table-column header-align="center" label="类型-事件描述" prop="logTitle" width="200">
          <template slot-scope="scope">
            <span>{{ scope.row.logType}}-{{ scope.row.logTitle }}</span>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="formChooseDialogParam.visible = false">取 消</el-button>
        <el-button v-if="formChooseDialogParam.action1" type="danger" @click="formChooseSubmit(false)">{{formChooseDialogParam.action1View}}</el-button>
        <el-button v-if="formChooseDialogParam.action2" type="primary" @click="formChooseSubmit(true)">{{formChooseDialogParam.action2View}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<style lang="less" scoped>
@import "@/assets/css/TableCus.css";
</style>
<script>

import store from '@/store';
import { getTableData, doNew, doModify, doDelete, doReset } from '@/api/dev/LogsSub'; //数据源,如果没有上传文件的部分,请删除postFile, postImg,不删除也不影响
import { required, phone, len, email } from "@/utils/formRules" //校验规则,如有特殊要求请自己去修改填写,请删除不使用的,但不删除也不影响
export default {
  components: {},
  data () {
    return {
      /*导航栏状态 */
      direcParam: {
        'directName': '名称名称名称名称名称名称名称名称名称LogsSub'
      },
      /*表格参数 */
      tableParam: {
        'page': 1,
        'limit': 40,
        'count': 0,
        'key': '',
        'tableKey': 0,
        'searchType': 'normal'
      },//表格的传参
      /*表格数据 */
      tableData: [],
      /*表格选中的数据 */
      tableMultipleSelection: [], //多选的数据
      /*表单的参数 */
      formDialogParam: //form弹出窗口的显示状态
      {
        visible: false,
        title: '编辑',
        formDataAction: '',
        width: '50%',
        top: '5vh',
      },
      formChooseDialogParam: //form发布弹出窗口的显示状态
      {
        formChooseAction: '',
        title: '',
        visible: false,
        action1: false,
        action1View: '',
        action2: false,
        action2View: ''
      },
      /*原始数据 */
      formRawData: {}, //由于不能直接清空所有的数据回到初使状态(表格参数据库和搜索数据不能清空),所以建立,在mount的时候作一个深拷贝,将formData的数据初使化的原始数据复制一份过来
      /*初使化数据 */
      formData: {
        devNo: '',
        waterNum: '',
        orderCode: '',
        place: 0,
        logType: 0,
        logTitle: '',
        logValue: 0,
        logValueUnit: '',
        logTime: '',

      },
      formDialogRules:
      {
        devNo: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        waterNum: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        orderCode: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        place: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        logType: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        logTitle: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        logValue: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        logValueUnit: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        logTime: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增

        // title: [required,len(0,255)],多规则绑定
        // mail: [required,len(0,255), email],多规则绑定
        // authorWriter: [required],
      },
    }
  },
  methods: {

    /*-------------------------------------------------表单(form)事件 -----------------------------------------------*/
    /*表单(form)-新建 */
    formDataNew () {
      this.formData = JSON.parse(JSON.stringify(this.formRawData)); //深拷贝一份原始数据到formData,就相当于重置表单了
      this.formDialogParam.title = '新建';
      this.formDialogParam.formDataAction = 'new';
      this.formDialogParam.visible = true;
    },
    /*表单(form)-修改 */
    formDataModify () {
      if (this.tableMultipleSelection.length != 1) {
        this.notifyInfo("您只能选择一条记录,才能修改");
      }
      else {
        this.formData = JSON.parse(JSON.stringify(this.tableMultipleSelection[0])); //深COPY主要是为了不想改了,就渲染到table上造成不必要的麻烦;
        this.formDialogParam.formDataAction = 'modify';
        this.formDialogParam.title = '修改';
        this.formDialogParam.visible = true;
      }

    },
    formDataReset () {
      this.$confirm('此操作将清空记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        doReset().then(response => {
          if (response.data.success) {
            this.tableParam.key = '';
            this.tableParam.page = 1;
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
            this.tableDateRefresh();
          }
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消清空'
        });
      });
    },
    /*表单(form)-删除 */
    formDataDelete () {
      if (this.tableMultipleSelection.length < 1) {
        this.notifyInfo("您必须最少选择一条记录,才能删除");
        return;
      }
      else {
        this.formChooseDialogParam.title = "是否真的要删除选定的记录(已发布的不删除)"
        this.formChooseDialogParam.formChooseAction = 'delete';
        this.formChooseDialogParam.action1 = true;
        this.formChooseDialogParam.action1View = '删 除';
        this.formChooseDialogParam.action2 = false;
        this.formChooseDialogParam.action2View = '';
        this.formChooseDialogParam.visible = true;
      }
    },
    formDataValidate () {
      let result = true;
      this.$refs.logsSubFormRef.validate((valid) => {
        result = valid;
      });
      return result;
    },
    /*表单(form)-提交 */
    formDataSubmit () {
      if (!this.formDataValidate()) return;
      this.formDialogParam.visible = false;
      //如有特殊的Json要求,请深Copy一份,再进行修改例如
      const postData = JSON.parse(JSON.stringify(this.formData));

      //postData.classId = this.tableParam.classId;
      //postData.imageUrl = JSON.stringify(this.formData.imageUrl);//由于MysqlJson的原因
      //postData.addFile = JSON.stringify(this.formData.addFile);//由于MysqlJson的原因
      //doNew(this.formData).then(response => {
      if (this.formDialogParam.formDataAction == 'new') {
        doNew(postData).then(response => {
          if (response.data.success) {
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
          }
        })
      }
      else if (this.formDialogParam.formDataAction == 'modify') {
        doModify(postData).then(response => {
          if (response.data.success) {
            this.tableParam.key = '';
            this.tableParam.page = 1;
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
            this.tableDateRefresh();
          }
        })
      }
    },
    formChooseSubmit () {
      this.formChooseDialogParam.visible = false;
      if (this.formChooseDialogParam.formChooseAction == 'delete') {
        const selectNum = this.tableMultipleSelection.length;
        let canId = [];
        for (let i = 0; i < selectNum; i++) {
          if (!this.tableMultipleSelection[i].isPublic) {
            canId.push(this.tableMultipleSelection[i].id);
          }
        }
        const data = new FormData()
        data.append('id', canId);
        doDelete(data).then(response => {
          if (response.data.success) {
            this.tableParam.key = '';
            this.tableParam.page = 1;
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
            this.tableDateRefresh();
          }
        })
      }
    },
    /*-------------------------------------------------表格事件 -----------------------------------------------*/
    //表格-多选事件,当checkBox被选中,改变已选的值
    tableSelectionChange (val) {
      this.tableMultipleSelection = val;
    }
    ,
    /*表格-行选,点击事件 */
    tableRowClick (row) {
      //this.formData = row;
      this.formData = JSON.parse(JSON.stringify(row));//主要是为了不想改了就生效到table上
      console.log(row)
      this.$refs.logsSubTableRef.clearSelection(); //清除选中
      this.$refs.logsSubTableRef.toggleRowSelection(row); //设定选中
    },
    /*表格-改变每页显示多少条,直接重置到第一页*/
    tableSizeChange (val) {
      if (val != this.tableParam.limit) {
        this.tableParam.limit = val
        this.tableParam.page = 1;
        this.tableDateRefresh();
      }
    },
    /*表格-改变第几页*/
    tableCurrentChange (val) {
      if (val != this.tableParam.page) {
        this.tableParam.page = val;
        this.tableDateRefresh();
      }

    },
    /*表格-搜索关键词,由于改变才会重搜,所以不用在意 */
    tableChangeSearchKey (searchKey) {
      this.tableParam.key = searchKey;
      this.tableParam.page = 1;
      this.tableDateRefresh();

    },
    /*表格-置亮搜索关键词 */
    tableLightByKey (val) {
      // 不区分大小写  const Reg = new RegExp(searchData, 'i');
      // 全局替换  const Reg = new RegExp(searchData, 'g');
      if (this.tableParam.key) {
        const Reg = new RegExp(this.tableParam.key, 'ig');
        if (val) {
          // 注意 这里推荐使用正则占位符$& 不使用${searchData}  因为当这里使用正则表达式（i）不区分大小写时，如果你的文本是大写，搜索的关键字是小写，匹配结果会替换掉大写的文本
          // const res = val.replace(Reg, `<span style="background-color: yellow;color:#ffffff">${searchData}</span>`);
          const res = val.replace(Reg, `<span style="background-color: #50bfff;color:#ffffff">$&</span>`);
          return res;
        }
      }
      else {
        return val;
      }
    },
    /*表格-数据重置 */
    tableDateRefresh () {
      getTableData(this.tableParam).then((jsonData) => {
        this.tableData = jsonData.data.data;
        this.tableParam.count = jsonData.data.count;
        this.tableParam.page = jsonData.data.page;
        this.tableParam.limit = jsonData.data.limit;
      })
    },

  },
  /*监听Id的变化,如果变化了就重新去取数据 */
  watch: {},
  filters: {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.direcParam = JSON.parse(store.getters.getActiveCus());
    this.formRawData = JSON.parse(JSON.stringify(this.formData)); //深拷贝一份原始数据到formRawData
    this.tableDateRefresh();
  }
}
</script>
