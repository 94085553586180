<template>
  <div>

    <el-tag :key="i" v-for="(val,key,i) in this.extParamInfo" closable :disable-transitions=" false" @click="extParamInfoEdit(key,val)"
      @close="extParamInfoDelete(key)" style="margin-right: 10px;">
      {{key}}:{{val}}
    </el-tag>
    <el-button size="mini" type="success" @click="extParamInfoNew()">+</el-button>
    <el-dialog v-dialogDrag :visible.sync="extParamInfoDialogParam.visible" :width="extParamInfoDialogParam.width" :close-on-click-modal='false' append-to-body>
      <template slot="title">
        <div style="font-size:14px;">{{extParamInfoDialogParam.title}}</div>
      </template>
      <el-form :model="extParamInfoData" label-width="100px" size="mini" ref="extParamInfoForm" :rules="extParamInfoDataRules">
        <el-form-item label="键名(key)" prop="key">
          <el-input v-model="extParamInfoData.key" placeholder="请输入英文键名(key)"></el-input>
        </el-form-item>
        <el-form-item label="值(value)" prop="value">
          <el-input v-model="extParamInfoData.value" placeholder="请输入值(value)"></el-input>
        </el-form-item>
        <el-form-item label="注意事项">
          <el-row>
            <el-col :span="24">1.请注意键名(key),符合HashMap的键名规则</el-col>
            <el-col :span="24">2.无论新建修改,只要键名相同,都是覆盖同键名记录</el-col>
            <el-col :span="24">3.无论新建修改,只有键名不同,才会成功</el-col>
            <el-col :span="24">3.修改值都是正常操作</el-col>
          </el-row>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="extParamInfoDialogParam.visible = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="extParamInfoPush()" size="mini">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { required } from "@/utils/formRules" //校验规则,如有特殊要求请自己去修改填写,请删除不使用的,但不删除也不影响
export default {
  props: { jsonInfo: { type: Object, required: true } },
  data () {
    return {
      extParamInfo: this.jsonInfo,
      /*-------------------------------------------------json extParamInfo 参数-----------------------------------------------*/
      extParamInfoDialogParam: {
        visible: false,
        title: '新建',
        width: '30%',
        top: '15vh',
      },
      /*--json 字段参数--*/
      extParamInfoRawData: {
        key: '',
        value: '',
      },
      extParamInfoData: {
        key: '',
        value: '',
      },
      extParamInfoDataRules:
      {
        key: [required],
        value: [required],
      },
      exitKey: '',//修改时原来的key
    };
  },
  methods: {
    /*-------------------------------------------------json extParamInfo 方法-----------------------------------------------*/
    extParamInfoNew () {
      this.extParamInfoData = JSON.parse(JSON.stringify(this.extParamInfoRawData))//重置
      this.extParamInfoDialogParam.title = "新建";
      this.extParamInfoDialogParam.visible = true;
    },
    extParamInfoPush () {
      this.$refs.extParamInfoForm.validate((valid) => {
        if (valid) {
          if (!this.exitKey) //如果存在exitKey,表示是修改,原来的key可能会被改了,这时候需要判断是否改过exitKey
          {
            this.$set(this.extParamInfo, this.extParamInfoData.key, this.extParamInfoData.value);
          }
          else if (this.exitKey == this.extParamInfoData.key) {
            this.$set(this.extParamInfo, this.extParamInfoData.key, this.extParamInfoData.value);
            this.exitKey = '';
          }
          else {
            this.$delete(this.extParamInfo, this.exitKey);
            this.$set(this.extParamInfo, this.extParamInfoData.key, this.extParamInfoData.value);
            this.exitKey = '';
          }
          this.extParamInfoDialogParam.visible = false;//不显示
          this.$emit('success', this.extParamInfo);
        }
      });
    },
    extParamInfoDelete (key) {
      this.$delete(this.extParamInfo, key);
      this.$emit('success', this.extParamInfo);
    },
    extParamInfoEdit (key, val) {
      this.exitKey = JSON.parse(JSON.stringify(key));
      console.log(this.exitKey);
      this.extParamInfoData.key = key;
      this.extParamInfoData.value = val;
      this.extParamInfoDialogParam.title = "修改";
      this.extParamInfoDialogParam.visible = true;
    },
  },
  filters: {},
  watch: {
    jsonInfo: function () {
      this.extParamInfo = this.jsonInfo;
    }
  },
  mounted () {
  },
}
</script>
