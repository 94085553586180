<template>
    <div>
    <span :key="index" v-for="(item,index) in this.extParam" style="margin-right:5px;">
      <el-tooltip placement="top">
        <div slot="content" style="line-height:20px">
          <!-- 这里修改注释 -->
          名称:{{item.name}}<br />详细:{{item.note}}<br />数量:{{item.num}}<br />单位:{{item.unit}}<br />
            <!-- 这里修改注释 -->
        </div>
        <el-tag @close="extParamDelete(item)" style="cursor: pointer;" @click="extParamEdit(item)" closable
                :disable-transitions="false">{{item.name}}</el-tag>
      </el-tooltip>
    </span>
        <el-button size="small" type="success" @click="extParamNew()">+</el-button>
        <el-dialog v-dialogDrag :visible.sync="extParamDialogParam.visible" :width="extParamDialogParam.width" :close-on-click-modal='false' append-to-body>
            <template slot="title">
                <div style="font-size:14px;">{{extParamDialogParam.title}}</div>
            </template>
            <el-form :model="extParamData" label-width="60px" size="small" ref="extParamForm" :rules="extParamDataRules">
                <!-- 这里修改表单项 -->
                <el-form-item label="名称" prop="name">
                    <el-input v-model="extParamData.name" placeholder="请输入名称"></el-input>
                </el-form-item>
                <el-form-item label="详细" prop="note">
                    <el-input type="textarea" :rows="4" resize="none" placeholder="请输入详细描述" v-model="extParamData.note">
                    </el-input>
                </el-form-item>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="数量" prop="num">
                            <el-input-number v-model="extParamData.num" controls-position="right" :precision="2" :step="1" :min="0" :max="100000"
                                             placeholder="数量"></el-input-number>
                        </el-form-item></el-col>
                    <el-col :span="12">
                        <el-form-item label="单位">
                            <el-input v-model="extParamData.unit" placeholder="请输入单位" style="width:100px"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <!-- 这里修改表单项 -->
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button @click="extParamDialogParam.visible = false">取 消</el-button>
        <el-button @click="extParamPush()" type="primary">确 定</el-button>
      </span>
        </el-dialog>
    </div>
</template>
<script>
    import { required } from "@/utils/formRules" //校验规则,如有特殊要求请自己去修改填写,请删除不使用的,但不删除也不影响
    export default {
        props: { jsonArray: { type: Array, required: true } },
        data () {
            return {
                extParam: this.jsonArray,
                /*-------------------------------------------------json extParam 参数-----------------------------------------------*/
                extParamDialogParam: {
                    visible: false,
                    title: '新建',
                    width: '50%',
                    top: '15vh',
                },
                /*--json 字段参数--*/
                extParamRawData: {
                    key: '',
                    name: '',
                    note: '',
                    num: 1,
                    unit: ''
                },
                extParamData: {
                    key: '',
                    name: '',
                    note: '',
                    num: 1,
                    unit: ''
                },
                extParamDataRules:
                        {
                            name: [required],
                            note: [required],
                            num: [required],
                        },
            };
        },
        methods: {
            /*-------------------------------------------------json extParam 方法-----------------------------------------------*/
            /**
             * 随机取数
             * @param {*} a
             * @param {*} b
             */
            cusRandom (a, b) {
                var n = Math.round(Math.random() * (a - b) + b);
                return n;
            },
            extParamNew () {
                this.extParamData = JSON.parse(JSON.stringify(this.extParamRawData))//重置
                let strData = "";
                //如果觉得12个数太少也可以多放点，将i<12修改即可
                for (var i = 0; i < 12; i++) {
                    let num = this.cusRandom(0, 9);                        //数字
                    let upper = String.fromCharCode(this.cusRandom(65, 90));     //大写字母
                    let lower = String.fromCharCode(this.cusRandom(97, 122));    //小写字母
                    strData = strData + num + upper + lower;                  //将所有结果放进strData中
                }
                let str = "";
                for (let i = 0; i < 12; i++) {
                    str += strData[this.cusRandom(0, strData.length - 1)];         //在strData里面随机抽取四个数
                }
                this.extParamData.key = str; //增加一个UID的标识为了修改
                this.extParamDialogParam.title = "新建";
                this.extParamDialogParam.visible = true;
            },
            extParamPush () {
                this.$refs.extParamForm.validate((valid) => {
                if (valid) {
                let index = this.extParam.findIndex((item) => item.key === this.extParamData.key);
                if (index > -1)//表示找到了
                {
                this.extParam.splice(index, 1, this.extParamData);
                }
                else {
                this.extParam.push(this.extParamData);//插到最后面
                }
                }
                this.extParamDialogParam.visible = false;//不显示
                this.$emit('success', this.extParam);
                });
            },
            extParamDelete (key) {
                let index = this.extParam.indexOf(key);//查出文件在哪
                // eslint-disable-next-line vue/no-mutating-props
                this.extParam.splice(index, 1);
                this.$emit('success', this.extParam);
            },
            extParamEdit (key) {
                let index = this.extParam.indexOf(key);//查出文件在哪
                this.extParamData = this.extParam[index];
                this.extParamDialogParam.title = "修改";
                this.extParamDialogParam.visible = true;
            },
        },
        filters: {},
        watch: {
            jsonArray: function () {
                this.extParam = this.jsonArray;
            }
        },
        mounted () {
        },
    }
</script>
