<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="18">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>{{ this.direcParam.directName }}</el-breadcrumb-item>
          <el-breadcrumb-item>
            <el-select v-model="tableParam.kindId" filterable placeholder="请选择分类" style="width:400px" size="small" ref="selectKind" @change="changeKind">
              <el-option v-for="(item,index) in this.allKinds" :key="index" :label="item.kindCode+' '+ item.madeIn+' '+item.kindName" :value="item.id">
              </el-option>
            </el-select>
          </el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
      <el-col :span="6">
        <div>
          <el-input placeholder="请输入搜索内容" v-model.trim="tableParam.key" style="width:100%" @change="tableChangeSearchKey">
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-button-group>
          <el-button @click="formDataNew" type="primary">新建</el-button>
          <el-button @click="formDataModify" type="primary">修改</el-button>
          <el-button @click="formDataDelete" type="primary">删除</el-button>
          <el-button @click="formDataLock" type="warning">锁定/解锁</el-button>
          <el-button @click="outMarkDown" type="success">导出 </el-button>
        </el-button-group>
      </el-col>
    </el-row>

    <el-table ref="kindParamTableRef" :key="tableParam.tableKey" :border="true" height="666" :cell-style="{height:'44px',padding:0,margin:0}" :data="tableData"
      tooltip-effect="dark" style="width: 100%" :highlight-current-row="true" @selection-change="tableSelectionChange" @row-click="tableRowClick">
      <el-table-column header-align="center" type="selection" width="40"></el-table-column>
      <el-table-column header-align="center" label="序" type="index" width="40"></el-table-column>
      <el-table-column header-align="center" label="命令" prop="cmdName" width="60">
        <template slot-scope="scope">
          <span v-html="tableLightByKey(scope.row.cmdName)"></span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" label="发起方" prop="startWith" width="80">
        <template slot-scope="scope">
          <el-tag effect="dark" v-if="scope.row.startWith==0">服务器</el-tag>
          <el-tag type='success' effect="dark" v-else-if="scope.row.startWith==1">客户机</el-tag>
          <el-tag type='danger' effect="dark" v-else>未知</el-tag>
        </template>
      </el-table-column>
      <el-table-column header-align="center" label="功能描述" prop="cmdTitle" width="200">
        <template slot-scope="scope">
          <span v-html="tableLightByKey(scope.row.cmdTitle)"></span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" label="发送参数" prop="param">
        <template slot-scope="scope">
          <span :key="index" v-for="(item,index) in scope.row.param" style="margin-right:5px;">
            <el-tooltip placement="top">
              <div slot="content" style="line-height:20px;">
                代码:{{item.code}}&nbsp;&nbsp;&nbsp;&nbsp;允许配置:{{item.allow}} <br />字节:{{item.num}}<br />位置:{{item.place}}<br />默认值:{{item.default}}<br />
                -----------------------以下说明-----------------------<br />
                <span v-html="formatTooltipContent(item.note)"></span>
              </div>
              <el-tag v-if="item.allow">{{item.code}}-{{item.name}}</el-tag>
              <el-tag v-else type='danger'>{{item.code}}-{{item.name}}</el-tag>
            </el-tooltip>
          </span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" label="返回参数" prop="reParam">
        <template slot-scope="scope">
          <span :key="index" v-for="(item,index) in scope.row.reParam" style="margin-right:5px;">
            <el-tooltip placement="top">
              <div slot="content" style="line-height:20px;">
                代码:{{item.code}}&nbsp;&nbsp;&nbsp;&nbsp;允许配置:{{item.allow}} <br />字节:{{item.num}}<br />位置:{{item.place}}<br />默认值:{{item.default}}<br />
                -----------------------以下说明-----------------------<br />
                <span v-html="formatTooltipContent(item.note)"></span>
              </div>
              <el-tag v-if="item.allow">{{item.code}}-{{item.name}}</el-tag>
              <el-tag v-else type='danger'>{{item.code}}-{{item.name}}</el-tag>
            </el-tooltip>
          </span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" label="备注与状态" width="100">
        <template slot-scope="scope">
          <span style="padding-left: 4px;" v-if="scope.row.note">
            <el-link :underline="false">
              <el-tooltip placement="top">
                <div slot="content" style="line-height:20px;">
                  <span v-html="formatTooltipContent(scope.row.note)"></span>
                </div>
                <i class="el-icon-info" style="font-size:16px;color: #409EFF;font-weight: 400;"></i>
              </el-tooltip>
            </el-link>
          </span>
          <span style="padding-left: 4px;" v-if="scope.row.isPublic">
            <el-link :underline="false">
              <el-tooltip class="item" effect="light" content="启用" placement="top-start">
                <i class="el-icon-folder-checked" style="font-size:16px;color: #409EFF;font-weight: 400;"></i>
              </el-tooltip>
            </el-link>
          </span>
          <span style="padding-left: 4px;" v-else>
            <el-link :underline="false">
              <el-tooltip class="item" effect="light" content="未启用" placement="top-start">
                <i class="el-icon-edit-outline" style="font-size:16px;color: #999999;font-weight: 400;"></i>
              </el-tooltip>
            </el-link>
          </span>
          <span style="padding-left: 4px;" v-if="scope.row.isLock">
            <el-link :underline="false">
              <el-tooltip class="item" effect="light" content="已锁定" placement="top-start">
                <i class="el-icon-lock" style="font-size:16px;color: #409EFF;font-weight: 400;"></i>
              </el-tooltip>
            </el-link>
          </span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @size-change="tableSizeChange" @current-change="tableCurrentChange" :current-page="tableParam.page"
      :page-sizes="[30,40, 50, 60,70,80,90,100]" :page-size="tableParam.limit" layout="total, sizes, prev, pager, next, jumper" :total="tableParam.count">
    </el-pagination>

    <!--编辑的表单-->

    <el-dialog v-dialogDrag :title="formDialogParam.title" :visible.sync="formDialogParam.visible" :width="formDialogParam.width" :top="formDialogParam.top"
      :close-on-click-modal='false'>
      <el-form ref="kindParamFormRef" :model="formData" :rules="formDialogRules" label-width="80px" size="small"><!-- `prop` 为绑定的规则 -->

        <el-row :gutter="20">
          <el-col :span="12">
            <el-row style="padding-bottom: 0px;" :gutter="20">
              <el-col :span="12">
                <el-form-item label="命令码" prop="cmdName">
                  <el-input id="cmdName" v-model="formData.cmdName" placeholder="请输入命令码"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="功能描述" prop="cmdTitle">
                  <el-input id="cmdTitle" v-model="formData.cmdTitle" placeholder="请输入功能描述"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="发起方" prop="startWith">
                  <el-select v-model="formData.startWith" placeholder="请选择发起方">
                    <el-option label="服务器" :value="0"></el-option>
                    <el-option label="客户机" :value="1"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="是否启用">
                  <el-checkbox v-model="formData.isPublic">是否启用</el-checkbox>
                </el-form-item>
              </el-col>

            </el-row>
            <div style="line-height:36px;height:36px;">发送参数</div>
            <table-param id="param" :jsonArray="formData.param" @success="paramSuccess"></table-param>
          </el-col>

          <el-col :span="12">
            <el-form-item label="备注说明" prop="note">
              <el-input type="textarea" :rows="4" resize="none" placeholder="请输入备注说明" style="height:84px;" v-model="formData.note">
              </el-input>
            </el-form-item>
            <div style="line-height:36px;height:36px;">接收参数</div>
            <table-param id="reParam" :jsonArray="formData.reParam" @success="reParamSuccess"></table-param>
          </el-col>
        </el-row>

        <!--<el-form-item label="参数及描述 array" prop="param">
    <table-param id="param" :jsonArray="formData.param" @success="paramSuccess"></table-param>
</el-form-item>-->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="formDialogParam.visible = false">取 消</el-button>
        <el-button type="primary" @click="formDataSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog v-dialogDrag :title="formChooseDialogParam.title" :visible.sync="formChooseDialogParam.visible" width="50%" :close-on-click-modal='false'>
      <el-table :data="tableMultipleSelection" style="width: 100%;" height="300">
        <el-table-column header-align="center" type="index" width="40"></el-table-column>
        <el-table-column header-align="center" label="命令码" prop="cmdName" width="80"></el-table-column>
        <el-table-column header-align="center" label="功能描述" prop="cmdTitle" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" label="状态" width="60">
          <template slot-scope="scope">
            <span style="padding-left: 4px;" v-if="scope.row.isLock">
              <el-link :underline="false">
                <el-tooltip class="item" effect="light" content="已锁定" placement="top-start">
                  <i class="el-icon-lock" style="font-size:16px;color: #409EFF;font-weight: 400;"></i>
                </el-tooltip>
              </el-link>
            </span>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-col :span="10">
          <el-input v-if="formChooseDialogParam.lockInputVisible" placeholder="锁定/解锁密码" v-model="formChooseDialogParam.lockPassValue" show-password>
          </el-input>
        </el-col>
        <el-button @click="formChooseDialogParam.visible = false">取 消</el-button>
        <el-button v-if="formChooseDialogParam.action1" type="danger" @click="formChooseSubmit(false)">{{formChooseDialogParam.action1View}}</el-button>
        <el-button v-if="formChooseDialogParam.action2" type="primary" @click="formChooseSubmit(true)">{{formChooseDialogParam.action2View}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<style lang="less" scoped>
@import "@/assets/css/TableCus.css";
</style>
<script>
import TableParam from '@/components/dev/kindParam/TableParam.vue';
import TagParam from '@/components/dev/kindParam/TagParam.vue';
import store from '@/store';

import { getAllPulbic } from '@/api/dev/Kind';
import { getTableData, doNew, doModify, doDelete, doLock } from '@/api/dev/KindParam'; //数据源,如果没有上传文件的部分,请删除postFile, postImg,不删除也不影响
import { required, phone, len, email } from "@/utils/formRules" //校验规则,如有特殊要求请自己去修改填写,请删除不使用的,但不删除也不影响
export default {
  components: { TagParam, TableParam },
  data () {
    return {
      /*导航栏状态 */
      downToken: '',
      allKinds: [],//所有的分类
      direcParam: {
        'directName': '名称名称名称名称名称名称名称名称名称KindParam'
      },
      /*表格参数 */
      tableParam: {
        'page': 1,
        'limit': 40,
        'count': 0,
        'key': '',
        'kindId': 0,
        'tableKey': 0,
        'searchType': 'normal'
      },//表格的传参
      /*表格数据 */
      tableData: [],
      /*表格选中的数据 */
      tableMultipleSelection: [], //多选的数据
      /*表单的参数 */
      formDialogParam: //form弹出窗口的显示状态
      {
        visible: false,
        title: '编辑',
        formDataAction: '',
        width: '90%',
        top: '5vh',
      },
      formChooseDialogParam: //form发布弹出窗口的显示状态
      {
        formChooseAction: '',
        title: '',
        visible: false,
        action1: false,
        action1View: '',
        action2: false,
        action2View: '',
        lockInputVisible: false,
        lockPassValue: '',
      },
      /*原始数据 */
      formRawData: {}, //由于不能直接清空所有的数据回到初使状态(表格参数据库和搜索数据不能清空),所以建立,在mount的时候作一个深拷贝,将formData的数据初使化的原始数据复制一份过来
      /*初使化数据 */
      formData: {
        kindId: 0,
        startWith: 0,
        cmdName: '',
        cmdTitle: '',
        param: [],
        reParam: [],
        note: '',
        isPublic: false,
        isLock: false,
        lockPass: '',

      },
      formDialogRules:
      {
        kindId: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        cmdName: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        cmdTitle: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        param: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        isPublic: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        isLock: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        lockPass: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增

        // title: [required,len(0,255)],多规则绑定
        // mail: [required,len(0,255), email],多规则绑定
        // authorWriter: [required],
      },
    }
  },
  methods: {

    outMarkDown () {  //下载markDown
      saveAs('/wbkj/kindParam/outMarkDown?downToken=' + this.downToken + '&kindId=' + this.tableParam.kindId, '帮助文档_' + new Date().toLocaleDateString() + '.md');

    },
    changeKind (val) {
      this.tableParam.kindId = val;
      this.tableDateRefresh();

    },
    /*-------------------------------------------------表单(form)事件 -----------------------------------------------*/
    /*表单(form)-新建 */
    formDataNew () {
      if (this.tableParam.kindId > 0) {
        this.formData = JSON.parse(JSON.stringify(this.formRawData)); //深拷贝一份原始数据到formData,就相当于重置表单了
        this.formData.kindId = this.tableParam.kindId;
        this.formDialogParam.title = '新建';
        this.formDialogParam.formDataAction = 'new';
        this.formDialogParam.visible = true;
      }
      else {
        this.notifyInfo("您只能先选一个分类,才能新建");
      }
    },
    /*表单(form)-修改 */
    formDataModify () {
      if (this.tableMultipleSelection.length != 1) {
        this.notifyInfo("您只能选择一条记录,才能修改");
      }
      else {
        this.formData = JSON.parse(JSON.stringify(this.tableMultipleSelection[0])); //深COPY主要是为了不想改了,就渲染到table上造成不必要的麻烦;
        this.formDialogParam.formDataAction = 'modify';
        this.formDialogParam.title = '修改';
        this.formDialogParam.visible = true;
      }

    },
    /*表单(form)-删除 */
    formDataDelete () {
      if (this.tableMultipleSelection.length < 1) {
        this.notifyInfo("您必须最少选择一条记录,才能删除");
        return;
      }
      else {
        this.formChooseDialogParam.title = "是否真的要删除选定的记录"
        this.formChooseDialogParam.formChooseAction = 'delete';
        this.formChooseDialogParam.action1 = true;
        this.formChooseDialogParam.action1View = '删 除';
        this.formChooseDialogParam.action2 = false;
        this.formChooseDialogParam.action2View = '';
        this.formChooseDialogParam.lockInputVisible = false;
        this.formChooseDialogParam.lockPassValue = '';
        this.formChooseDialogParam.visible = true;
      }
    },
    formDataLock () {
      if (this.tableMultipleSelection.length < 1) {
        this.notifyInfo("您必须最少选择一条记录,才能锁定/解锁");
        return;
      }
      else {
        this.formChooseDialogParam.title = "是否真的要锁定/解锁选定的记录"
        this.formChooseDialogParam.formChooseAction = 'doLock';
        this.formChooseDialogParam.action1 = true;
        this.formChooseDialogParam.action1View = '解 锁';
        this.formChooseDialogParam.action2 = true;
        this.formChooseDialogParam.action2View = '锁 定';
        this.formChooseDialogParam.lockInputVisible = true;
        this.formChooseDialogParam.lockPassValue = '';
        this.formChooseDialogParam.visible = true;
      }
    },
    formDataValidate () {
      let result = true;
      this.$refs.kindParamFormRef.validate((valid) => {
        result = valid;
      });
      return result;
    },
    /*表单(form)-提交 */
    formDataSubmit () {
      if (!this.formDataValidate()) return;
      this.formDialogParam.visible = false;
      //如有特殊的Json要求,请深Copy一份,再进行修改例如
      const postData = JSON.parse(JSON.stringify(this.formData));
      postData.param = JSON.stringify(this.formData.param);//由于MysqlJson的原因
      postData.reParam = JSON.stringify(this.formData.reParam);//由于MysqlJson的原因
      //postData.classId = this.tableParam.classId;
      //postData.imageUrl = JSON.stringify(this.formData.imageUrl);//由于MysqlJson的原因
      //postData.addFile = JSON.stringify(this.formData.addFile);//由于MysqlJson的原因
      //doNew(this.formData).then(response => {
      if (this.formDialogParam.formDataAction == 'new') {
        doNew(postData).then(response => {
          if (response.data.success) {
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
          }
        })
      }
      else if (this.formDialogParam.formDataAction == 'modify') {
        doModify(postData).then(response => {
          if (response.data.success) {
            this.tableParam.key = '';
            this.tableParam.page = 1;
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
            this.tableDateRefresh();
          }
        })
      }
    },
    formChooseSubmit (isTrueOrFalse) {
      this.formChooseDialogParam.visible = false;
      if (this.formChooseDialogParam.formChooseAction == 'delete') {
        const selectNum = this.tableMultipleSelection.length;
        let canId = [];
        for (let i = 0; i < selectNum; i++) {
          if (!this.tableMultipleSelection[i].isPublic && !this.tableMultipleSelection[i].isLock) {
            canId.push(this.tableMultipleSelection[i].id);
          }
        }
        if (canId.length == 0) {
          this.notifyWarning("锁定和正在使用的都不能删除");
          return;
        }
        const data = new FormData()
        data.append('id', canId);
        doDelete(data).then(response => {
          if (response.data.success) {
            this.tableParam.key = '';
            this.tableParam.page = 1;
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
            this.tableDateRefresh();
          }
        })
      }
      else if (this.formChooseDialogParam.formChooseAction == 'doLock') {
        if (this.formChooseDialogParam.lockPassValue == '' || this.formChooseDialogParam.lockPassValue.length < 6) {
          this.notifyWarning("密码信息不能为空,必须为6-8位");
          return;
        }
        else {
          this.formChooseDialogParam.visible = false;
        }
        const selectNum = this.tableMultipleSelection.length;
        let canId = [];
        for (let i = 0; i < selectNum; i++) {
          canId.push(this.tableMultipleSelection[i].id);
        }
        const data = new FormData()
        data.append('id', canId);
        data.append('isLock', isTrueOrFalse);
        data.append('lockPass', this.formChooseDialogParam.lockPassValue)
        console.log(data);
        doLock(data).then(response => {
          if (response.data.success) {
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
            this.tableDateRefresh();
          }
        })
      }
    },
    /*-------------------------------------------------表格事件 -----------------------------------------------*/
    //表格-多选事件,当checkBox被选中,改变已选的值
    tableSelectionChange (val) {
      this.tableMultipleSelection = val;
    }
    ,
    /*表格-行选,点击事件 */
    tableRowClick (row) {
      //this.formData = row;
      this.formData = JSON.parse(JSON.stringify(row));//主要是为了不想改了就生效到table上
      console.log(row)
      this.$refs.kindParamTableRef.clearSelection(); //清除选中
      this.$refs.kindParamTableRef.toggleRowSelection(row); //设定选中
    },
    /*表格-改变每页显示多少条,直接重置到第一页*/
    tableSizeChange (val) {
      if (val != this.tableParam.limit) {
        this.tableParam.limit = val
        this.tableParam.page = 1;
        this.tableDateRefresh();
      }
    },
    /*表格-改变第几页*/
    tableCurrentChange (val) {
      if (val != this.tableParam.page) {
        this.tableParam.page = val;
        this.tableDateRefresh();
      }

    },
    /*表格-搜索关键词,由于改变才会重搜,所以不用在意 */
    tableChangeSearchKey (searchKey) {
      this.tableParam.key = searchKey;
      this.tableParam.page = 1;
      this.tableDateRefresh();

    },
    /*表格-置亮搜索关键词 */
    tableLightByKey (val) {
      // 不区分大小写  const Reg = new RegExp(searchData, 'i');
      // 全局替换  const Reg = new RegExp(searchData, 'g');
      if (this.tableParam.key) {
        const Reg = new RegExp(this.tableParam.key, 'ig');
        if (val) {
          // 注意 这里推荐使用正则占位符$& 不使用${searchData}  因为当这里使用正则表达式（i）不区分大小写时，如果你的文本是大写，搜索的关键字是小写，匹配结果会替换掉大写的文本
          // const res = val.replace(Reg, `<span style="background-color: yellow;color:#ffffff">${searchData}</span>`);
          const res = val.replace(Reg, `<span style="background-color: #50bfff;color:#ffffff">$&</span>`);
          return res;
        }
      }
      else {
        return val;
      }
    },
    /*表格-数据重置 */
    tableDateRefresh () {
      getTableData(this.tableParam).then((jsonData) => {
        this.tableData = jsonData.data.data;
        this.tableParam.count = jsonData.data.count;
        this.tableParam.page = jsonData.data.page;
        this.tableParam.limit = jsonData.data.limit;
      })
    },
    paramSuccess (data) { //JsonArray,数据回传param成功,更新数据
      this.formData.param = data;
    },
    reParamSuccess (data) { //JsonArray,数据回传reParam成功,更新数据
      this.formData.reParam = data;
    },


  },
  /*监听Id的变化,如果变化了就重新去取数据 */
  watch: {},
  filters: {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.direcParam = JSON.parse(store.getters.getActiveCus());
    getAllPulbic().then(response => {
      if (response.data.success) {
        this.allKinds = response.data.data;
        if (this.allKinds.length > 0) {
          this.tableParam.kindId = this.allKinds[0].id;
          this.formRawData = JSON.parse(JSON.stringify(this.formData)); //深拷贝一份原始数据到formRawData
          this.tableDateRefresh();

        }
      }
      else {
        this.notifyWarning(response.data.msg);
      }
    });
    this.downToken = store.getters.getToken();
  }
}
</script>
