<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="19">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>{{ this.direcParam.directName }}</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
      <el-col :span="5">
        <div>
          <el-input placeholder="请输入搜索内容" v-model.trim="tableParam.key" style="width:100%" @change="tableChangeSearchKey">
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-button-group>
          <el-button @click="formDataNew" type="primary">新建</el-button>
          <el-button @click="formDataModify" type="primary">修改</el-button>
          <el-button @click="formDataDelete" type="primary">删除</el-button>
        </el-button-group>
      </el-col>
    </el-row>

    <el-table ref="infoTableRef" :key="tableParam.tableKey" :border="true" height="666" :cell-style="{height:'44px',padding:0,margin:0}" :data="tableData"
      tooltip-effect="dark" style="width: 100%" :highlight-current-row="true" @selection-change="tableSelectionChange" @row-click="tableRowClick">
      <el-table-column header-align="center" type="selection" width="40"></el-table-column>
      <el-table-column header-align="center" type="index" width="40"></el-table-column>
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-descriptions labelStyle="width:90px;text-align:right" :column="4" style="margin-left:10px;margin-right:10px;" border size="small">
            <el-descriptions-item label="设备类型">{{ props.row.kindName }}-{{ props.row.plugNum }}插</el-descriptions-item>
            <el-descriptions-item label="外壳条码">{{props.row.devCode}}002.24032615</el-descriptions-item>
            <el-descriptions-item label="经纬度">经:{{props.row.lon}} 纬:{{props.row.lat}} </el-descriptions-item>
            <el-descriptions-item label="区域及代码">{{props.row.province}}-{{props.row.city}}-{{props.row.area}} 代码:{{props.row.areaCode}}</el-descriptions-item>

            <el-descriptions-item :span="2" label="联系地址">{{ props.row.address }}</el-descriptions-item>
            <el-descriptions-item label="最后使用">{{props.row.lastDate}}</el-descriptions-item>
            <el-descriptions-item label="最近上下线">{{props.row.recentUpTime}}&nbsp;{{props.row.recentDownTime}}</el-descriptions-item>

            <el-descriptions-item :span="2" label="其它参数">
              <span v-for="(item,index) in props.row.otherInfo" style="margin-right:5px;" size="mini" :key="'other_info'+item.id">{{item.cnName}}:{{item.value}}
              </span>
            </el-descriptions-item>
            <el-descriptions-item :span="2" label="网络信息">
              <span v-for="(item,index) in props.row.netInfo" :key="'netInfo_'+item.id" style="margin-right:5px">{{item.cnName}}:{{item.value}}</span>
            </el-descriptions-item>
            <el-descriptions-item :span="4" label="参数信息">
              <el-tag type="info" v-for="(item,index) in props.row.paramInfo" :key="'paramInfo_'+item.id" size="small"
                style="margin-right:5px;margin-bottom:5px;">{{item.title}}:{{item.cnName}}</el-tag>
            </el-descriptions-item>
            <!--
            <el-descriptions-item :span="4" label="网络信息">
              <el-descriptions :column="3" size="mini">
                <el-descriptions-item v-for="(item,index) in props.row.netInfo" :key="'netInfo_'+item.id"
                  :label="item.cnName">{{item.value}}</el-descriptions-item>
              </el-descriptions>
            </el-descriptions-item>

            <el-descriptions-item :span="4" label="参数信息">
              <el-descriptions :column="3" size="mini">
                <el-descriptions-item v-for="(item,index) in props.row.paramInfo" :key="'paramInfo_'+item.id"
                  :label="item.title">{{item.cnName}}-{{item.value}}</el-descriptions-item>
              </el-descriptions>
            </el-descriptions-item> -->

          </el-descriptions>
        </template>
      </el-table-column>
      <el-table-column header-align="center" label="编号/设备号" prop="devNo" width="120">
        <template slot-scope="scope">
          <span v-html="tableLightByKey(scope.row.devNo)"></span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" label="区域代码" prop="areaCode" width="80">
        <template slot-scope="scope">
          <span v-html="tableLightByKey(scope.row.areaCode)"></span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" label="小区地址" prop="address" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-html="tableLightByKey(scope.row.address)"></span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" label="信号" prop="signValue" width="70" align="center">
        <template slot-scope="scope">
          <el-tooltip class="item" effect="dark" :content="getSignaTextByDepth(scope.row.signValue)" placement="top-start">
            <i class="fa fa-signal" :style="'color:'+getSignalColorByDepth(scope.row.signValue)"></i>
          </el-tooltip>&nbsp;
          <el-tooltip class="item" effect="dark" content="在线" placement="top-start" v-if="scope.row.isOnline">
            <i class="fa fa-link" style="color:#67C23A"></i>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="离线" placement="top-start" v-else>
            <i class="fa fa-unlink" style="color:#ef0000"></i>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column header-align="center" label="创建时间" prop="createTime" width="140"></el-table-column>

      <el-table-column header-align="center" label="插座" width="470">
        <template slot-scope="scope">

          <el-popover placement="top" :width="30" trigger="click" v-for="item in scope.row.plugStatus">
            <div style="text-align: center;">
              <el-button size="mini" style="padding:5px;" @click="sendStartData(scope.row.devNo,item.place)">
                <i class="fa fa-play" style="color:#409EFF" :key="'powerStart'+item">&nbsp;</i>启动
              </el-button>
              <el-button size="mini" style="padding:5px;" @click="sendStopData(scope.row.devNo,item.place)">
                <i class="fa fa-power-off" style="color:#ef0000" :key="'powerStop'+item">&nbsp;</i>停止
              </el-button>
            </div>
            <el-button slot="reference" size="mini" style="padding:5px;">
              <i class="fa fa-plug" v-if="item.isOpen" style="color:#409EFF" :key="'power'+item.place"> {{ item.place }}</i>
              <i class="fa fa-life-buoy" v-else style="color:#909399" :key="'power'+item.place"> {{ item.place }}</i>
            </el-button>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column label="信息" width="140">
        <template slot-scope="scope">
          <el-popover placement="bottom" width="200" trigger="click">
            <img :src="devQrUrl" style="width:200px;" />
            <i class="fa fa-qrcode" style="color:#409EFF" title="设备二维码" slot="reference" @click="createQrCode(scope.row.devNo)"></i>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="140">
        <template slot-scope="scope">
          <i class="el-icon-setting" style="color:#E6A23C" title="恢复出厂设置" @click="sendInitDefault(scope.row.devNo)"></i>&nbsp;&nbsp;&nbsp;&nbsp;
          <i class="el-icon-location" style="color:#409EFF" title="坐标反查更新" @click="updateLocation(scope.row.devNo)"></i>&nbsp;
          <i class="fa fa-search" style="color:#409EFF" title="参数查询" @click="sendSearchParam(scope.row.devNo)"></i>&nbsp;
          <i class="fa fa-sliders" style="color:#409EFF" title="下发设备参数"></i>&nbsp;
          <i class="fa fa-refresh" style="color:#67C23A" title="重启" @click="sendRestartData(scope.row.devNo)"></i>&nbsp;
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @size-change="tableSizeChange" @current-change="tableCurrentChange" :current-page="tableParam.page"
      :page-sizes="[30,40, 50, 60,70,80,90,100]" :page-size="tableParam.limit" layout="total, sizes, prev, pager, next, jumper" :total="tableParam.count">
    </el-pagination>

    <!--编辑的表单-->

    <el-dialog v-dialogDrag :title="formDialogParam.title" :visible.sync="formDialogParam.visible" :width="formDialogParam.width" :top="formDialogParam.top"
      :close-on-click-modal='false'>
      <el-form ref="infoFormRef" :model="formData" :rules="formDialogRules" label-width="80px" size="small"><!-- `prop` 为绑定的规则 -->
        <el-form-item label="类型ID" prop="kindId">
          <el-input-number id="kindId" v-model="formData.kindId" controls-position="right" :min="0" :max="2147483647" placeholder="请输入类型ID"></el-input-number>
        </el-form-item>

        <el-form-item label="类型名称" prop="kindName">
          <el-input id="kindName" v-model="formData.kindName" placeholder="请输入类型名称"></el-input>
        </el-form-item>

        <el-form-item label="编号/设备号" prop="devNo">
          <el-input id="devNo" v-model="formData.devNo" placeholder="请输入编号/设备号"></el-input>
        </el-form-item>

        <el-form-item label="外壳条码" prop="devCode">
          <el-input id="devCode" v-model="formData.devCode" placeholder="请输入外壳条码"></el-input>
        </el-form-item>

        <el-form-item label="SIM卡ID" prop="simId">
          <el-input id="simId" v-model="formData.simId" placeholder="请输入SIM卡ID"></el-input>
        </el-form-item>

        <el-form-item label="区域代码" prop="areaCode">
          <el-input id="areaCode" v-model="formData.areaCode" placeholder="请输入区域代码"></el-input>
        </el-form-item>

        <el-form-item label="所在省" prop="province">
          <el-input id="province" v-model="formData.province" placeholder="请输入所在省"></el-input>
        </el-form-item>

        <el-form-item label="所在市" prop="city">
          <el-input id="city" v-model="formData.city" placeholder="请输入所在市"></el-input>
        </el-form-item>

        <el-form-item label="所在区" prop="area">
          <el-input id="area" v-model="formData.area" placeholder="请输入所在区"></el-input>
        </el-form-item>

        <el-form-item label="小区地址" prop="address">
          <el-input id="address" v-model="formData.address" placeholder="请输入小区地址"></el-input>
        </el-form-item>

        <el-form-item label="经度" prop="lon">
          <el-input id="lon" v-model="formData.lon" placeholder="请输入经度"></el-input>
        </el-form-item>

        <el-form-item label="纬度" prop="lat">
          <el-input id="lat" v-model="formData.lat" placeholder="请输入纬度"></el-input>
        </el-form-item>

        <el-form-item label="信号强度" prop="signValue">
          <el-input id="signValue" v-model="formData.signValue" placeholder="请输入信号强度"></el-input>
        </el-form-item>

        <el-form-item label="创建时间" prop="createTime">
          <el-date-picker id="createTime" v-model="formData.createTime" type="datetime" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm"
            placeholder="请选择创建时间"></el-date-picker>
        </el-form-item>

        <el-form-item label="最后使用日期" prop="lastDate">
          <el-date-picker id="lastDate" v-model="formData.lastDate" value-format="yyyy-MM-dd" format="yyyy-MM-dd" type="date"
            placeholder="请选择最后使用日期"></el-date-picker>
        </el-form-item>

        <el-form-item label="近期上线时间" prop="recentUpTime">
          <el-date-picker id="recentUpTime" v-model="formData.recentUpTime" type="datetime" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm"
            placeholder="请选择近期上线时间"></el-date-picker>
        </el-form-item>

        <el-form-item label="近期下线时间" prop="recentDownTime">
          <el-date-picker id="recentDownTime" v-model="formData.recentDownTime" type="datetime" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm"
            placeholder="请选择近期下线时间"></el-date-picker>
        </el-form-item>

        <el-form-item label="是否在线">
          <el-checkbox v-model="formData.isOnline">是否在线</el-checkbox>
        </el-form-item>

        <el-form-item label="其它信息(array)" prop="otherInfo">
          <tag-other-info id="otherInfo" :jsonArray="formData.otherInfo" @success="otherInfoSuccess"></tag-other-info>
        </el-form-item>
        <!--<el-form-item label="其它信息(array)" prop="otherInfo">
    <table-other-info id="otherInfo" :jsonArray="formData.otherInfo" @success="otherInfoSuccess"></table-other-info>
</el-form-item>-->

      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="formDialogParam.visible = false">取 消</el-button>
        <el-button type="primary" @click="formDataSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog v-dialogDrag :title="formChooseDialogParam.title" :visible.sync="formChooseDialogParam.visible" width="50%" :close-on-click-modal='false'>
      <el-table :data="tableMultipleSelection" style="width: 100%;" height="300">
        <el-table-column header-align="center" type="index" width="40"></el-table-column>
        <el-table-column prop="title" label="选定的记录">
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="formChooseDialogParam.visible = false">取 消</el-button>
        <el-button v-if="formChooseDialogParam.action1" type="danger" @click="formChooseSubmit(false)">{{formChooseDialogParam.action1View}}</el-button>
        <el-button v-if="formChooseDialogParam.action2" type="primary" @click="formChooseSubmit(true)">{{formChooseDialogParam.action2View}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<style lang="less" scoped>
@import "@/assets/css/TableCus.css";
</style>
<script>
import TableOtherInfo from '@/components/dev/info/TableOtherInfo.vue';
import TagOtherInfo from '@/components/dev/info/TagOtherInfo.vue';

import store from '@/store';
import { getDevQrCode } from '@/api/utils/QrCode';
import { getTableData, doNew, doModify, doDelete, sendStart, sendStop, sendRestart, initDefault, searchParam, locationUpdate } from '@/api/dev/Info'; //数据源,如果没有上传文件的部分,请删除postFile, postImg,不删除也不影响
import { required, phone, len, email } from "@/utils/formRules" //校验规则,如有特殊要求请自己去修改填写,请删除不使用的,但不删除也不影响
export default {
  components: { TableOtherInfo, TagOtherInfo },
  data () {
    return {
      devQrUrl: '',

      /*导航栏状态 */
      direcParam: {
        'directName': '名称名称名称名称名称名称名称名称名称Info'
      },
      /*表格参数 */
      tableParam: {
        'page': 1,
        'limit': 40,
        'count': 0,
        'key': '',
        'tableKey': 0,
        'searchType': 'normal'
      },//表格的传参
      /*表格数据 */
      tableData: [],
      /*表格选中的数据 */
      tableMultipleSelection: [], //多选的数据
      /*表单的参数 */
      formDialogParam: //form弹出窗口的显示状态
      {
        visible: false,
        title: '编辑',
        formDataAction: '',
        width: '50%',
        top: '5vh',
      },
      formChooseDialogParam: //form发布弹出窗口的显示状态
      {
        formChooseAction: '',
        title: '',
        visible: false,
        action1: false,
        action1View: '',
        action2: false,
        action2View: ''
      },
      /*原始数据 */
      formRawData: {}, //由于不能直接清空所有的数据回到初使状态(表格参数据库和搜索数据不能清空),所以建立,在mount的时候作一个深拷贝,将formData的数据初使化的原始数据复制一份过来
      /*初使化数据 */
      formData: {
        kindId: 0,
        kindName: '',
        devNo: '',
        devCode: '',
        simId: '',
        areaCode: '',
        province: '',
        city: '',
        area: '',
        address: '',
        lon: '',
        lat: '',
        signValue: '',
        createTime: '',
        lastDate: new Date(),
        recentUpTime: '',
        recentDownTime: '',
        isOnline: false,

        otherInfo: [],
        plugNum: 0,
        plugStatus: [],
        netInfo: [],
        paramInfo: [],


      },
      formDialogRules:
      {
        kindId: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        kindName: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        devNo: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        devCode: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        simId: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        areaCode: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        province: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        city: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        area: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        address: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        lon: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        lat: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        signValue: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        createTime: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        lastDate: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        recentUpTime: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        recentDownTime: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        isOnline: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        otherInfo: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增

        // title: [required,len(0,255)],多规则绑定
        // mail: [required,len(0,255), email],多规则绑定
        // authorWriter: [required],
      },
    }
  },
  methods: {

    /**生成设备的QR码 **/
    createQrCode (qrStr) {
      getDevQrCode({ qrStr: qrStr }).then((response) => {
        if (response.data.success) {
          this.devQrUrl = response.data.data;
        }
        else {
          this.notifyWarning(response.data.msg + ":" + response.data.data);
        }
      })
    },
    /**信号强度的函数 */
    getSignalColorByDepth (signalStrength) {
      let value = signalStrength / 8;
      if (value > 3) {
        return '#409EFF';
      }
      else if (value > 2) {
        return '#67C23A';

      }
      else if (value > 1) {
        return '#E6A23C';

      }
      else if (value > 0) {
        return '#F56C6C';
      }
      else {
        return '#909399';
      }
    },
    getSignaTextByDepth (signalStrength) {
      let value = signalStrength / 5;
      if (value > 3) {
        return '信号:强-' + signalStrength;
      }
      else if (value > 2) {
        return '信号:正常-' + signalStrength;

      }
      else if (value > 1) {
        return '信号:微弱-' + signalStrength;

      }
      else if (value > 0) {
        return '信号:弱-' + signalStrength;
      }
      else {
        return '信号:极弱-' + signalStrength;
      }
    },

    sendStartData (devNo, index) {
      const data = new FormData()
      data.append('devNo', devNo);
      data.append('index', index);
      sendStart(data).then(response => {
        if (response.data.success) {
          this.notifySuccess(response.data.msg);
          this.tableDateRefresh();
        }
        else {
          this.notifyWarning(response.data.msg);
          this.tableDateRefresh();
        }
      })
    },
    sendStopData (devNo, index) {
      const data = new FormData()
      data.append('devNo', devNo);
      data.append('index', index);
      sendStop(data).then(response => {
        if (response.data.success) {
          this.notifySuccess(response.data.msg);
          this.tableDateRefresh();
        }
        else {
          this.notifyWarning(response.data.msg);
          this.tableDateRefresh();
        }
      })
    },
    /**下发查询参数命令**/
    sendSearchParam (devNo) {
      const data = new FormData()
      data.append('devNo', devNo);
      searchParam(data).then(response => {
        if (response.data.success) {
          this.notifySuccess(response.data.msg);
          this.tableDateRefresh();
        }
        else {
          this.notifyWarning(response.data.msg);
          this.tableDateRefresh();
        }
      })
    },
    /**恢复出厂设置**/
    sendInitDefault (devNo) {
      const data = new FormData()
      data.append('devNo', devNo);
      initDefault(data).then(response => {
        if (response.data.success) {
          this.notifySuccess(response.data.msg);
          this.tableDateRefresh();
        }
        else {
          this.notifyWarning(response.data.msg);
          this.tableDateRefresh();
        }
      })
    },
    updateLocation (devNo) {
      const data = new FormData()
      data.append('devNo', devNo);
      locationUpdate(data).then(response => {
        if (response.data.success) {
          this.notifySuccess(response.data.msg);
          this.tableDateRefresh();
        }
        else {
          this.notifyWarning(response.data.msg);
          this.tableDateRefresh();
        }
      })
    },

    sendRestartData (devNo) {
      const data = new FormData()
      data.append('devNo', devNo);
      sendRestart(data).then(response => {
        if (response.data.success) {
          this.notifySuccess(response.data.msg);
          this.tableDateRefresh();
        }
        else {
          this.notifyWarning(response.data.msg);
          this.tableDateRefresh();
        }
      })
    },
    /*-------------------------------------------------表单(form)事件 -----------------------------------------------*/
    /*表单(form)-新建 */
    formDataNew () {
      this.formData = JSON.parse(JSON.stringify(this.formRawData)); //深拷贝一份原始数据到formData,就相当于重置表单了
      this.formDialogParam.title = '新建';
      this.formDialogParam.formDataAction = 'new';
      this.formDialogParam.visible = true;
    },
    /*表单(form)-修改 */
    formDataModify () {
      if (this.tableMultipleSelection.length != 1) {
        this.notifyInfo("您只能选择一条记录,才能修改");
      }
      else {
        this.formData = JSON.parse(JSON.stringify(this.tableMultipleSelection[0])); //深COPY主要是为了不想改了,就渲染到table上造成不必要的麻烦;
        this.formDialogParam.formDataAction = 'modify';
        this.formDialogParam.title = '修改';
        this.formDialogParam.visible = true;
      }

    },
    /*表单(form)-删除 */
    formDataDelete () {
      if (this.tableMultipleSelection.length < 1) {
        this.notifyInfo("您必须最少选择一条记录,才能删除");
        return;
      }
      else {
        this.formChooseDialogParam.title = "是否真的要删除选定的记录(已发布的不删除)"
        this.formChooseDialogParam.formChooseAction = 'delete';
        this.formChooseDialogParam.action1 = true;
        this.formChooseDialogParam.action1View = '删 除';
        this.formChooseDialogParam.action2 = false;
        this.formChooseDialogParam.action2View = '';
        this.formChooseDialogParam.visible = true;
      }
    },
    formDataValidate () {
      let result = true;
      this.$refs.infoFormRef.validate((valid) => {
        result = valid;
      });
      return result;
    },
    /*表单(form)-提交 */
    formDataSubmit () {
      if (!this.formDataValidate()) return;
      this.formDialogParam.visible = false;
      //如有特殊的Json要求,请深Copy一份,再进行修改例如
      const postData = JSON.parse(JSON.stringify(this.formData));
      postData.otherInfo = JSON.stringify(this.formData.otherInfo);//由于MysqlJson的原因

      //postData.classId = this.tableParam.classId;
      //postData.imageUrl = JSON.stringify(this.formData.imageUrl);//由于MysqlJson的原因
      //postData.addFile = JSON.stringify(this.formData.addFile);//由于MysqlJson的原因
      //doNew(this.formData).then(response => {
      if (this.formDialogParam.formDataAction == 'new') {
        doNew(postData).then(response => {
          if (response.data.success) {
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
          }
        })
      }
      else if (this.formDialogParam.formDataAction == 'modify') {
        doModify(postData).then(response => {
          if (response.data.success) {
            this.tableParam.key = '';
            this.tableParam.page = 1;
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
            this.tableDateRefresh();
          }
        })
      }
    },
    formChooseSubmit () {
      this.formChooseDialogParam.visible = false;
      if (this.formChooseDialogParam.formChooseAction == 'delete') {
        const selectNum = this.tableMultipleSelection.length;
        let canId = [];
        for (let i = 0; i < selectNum; i++) {
          if (!this.tableMultipleSelection[i].isPublic) {
            canId.push(this.tableMultipleSelection[i].id);
          }
        }
        const data = new FormData()
        data.append('id', canId);
        doDelete(data).then(response => {
          if (response.data.success) {
            this.tableParam.key = '';
            this.tableParam.page = 1;
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
            this.tableDateRefresh();
          }
        })
      }
    },
    /*-------------------------------------------------表格事件 -----------------------------------------------*/
    //表格-多选事件,当checkBox被选中,改变已选的值
    tableSelectionChange (val) {
      this.tableMultipleSelection = val;
    }
    ,
    /*表格-行选,点击事件 */
    tableRowClick (row) {
      //this.formData = row;
      this.formData = JSON.parse(JSON.stringify(row));//主要是为了不想改了就生效到table上
      console.log(row)
      this.$refs.infoTableRef.clearSelection(); //清除选中
      this.$refs.infoTableRef.toggleRowSelection(row); //设定选中
    },
    /*表格-改变每页显示多少条,直接重置到第一页*/
    tableSizeChange (val) {
      if (val != this.tableParam.limit) {
        this.tableParam.limit = val
        this.tableParam.page = 1;
        this.tableDateRefresh();
      }
    },
    /*表格-改变第几页*/
    tableCurrentChange (val) {
      if (val != this.tableParam.page) {
        this.tableParam.page = val;
        this.tableDateRefresh();
      }

    },
    /*表格-搜索关键词,由于改变才会重搜,所以不用在意 */
    tableChangeSearchKey (searchKey) {
      this.tableParam.key = searchKey;
      this.tableParam.page = 1;
      this.tableDateRefresh();

    },
    /*表格-置亮搜索关键词 */
    tableLightByKey (val) {
      // 不区分大小写  const Reg = new RegExp(searchData, 'i');
      // 全局替换  const Reg = new RegExp(searchData, 'g');
      if (this.tableParam.key) {
        const Reg = new RegExp(this.tableParam.key, 'ig');
        if (val) {
          // 注意 这里推荐使用正则占位符$& 不使用${searchData}  因为当这里使用正则表达式（i）不区分大小写时，如果你的文本是大写，搜索的关键字是小写，匹配结果会替换掉大写的文本
          // const res = val.replace(Reg, `<span style="background-color: yellow;color:#ffffff">${searchData}</span>`);
          const res = val.replace(Reg, `<span style="background-color: #50bfff;color:#ffffff">$&</span>`);
          return res;
        }
      }
      else {
        return val;
      }
    },
    /*表格-数据重置 */
    tableDateRefresh () {
      getTableData(this.tableParam).then((jsonData) => {
        this.tableData = jsonData.data.data;
        this.tableParam.count = jsonData.data.count;
        this.tableParam.page = jsonData.data.page;
        this.tableParam.limit = jsonData.data.limit;
      })
    },
    otherInfoSuccess (data) { //JsonArray,数据回传otherInfo成功,更新数据
      this.formData.otherInfo = data;
    },


  },
  /*监听Id的变化,如果变化了就重新去取数据 */
  watch: {},
  filters: {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.direcParam = JSON.parse(store.getters.getActiveCus());
    this.formRawData = JSON.parse(JSON.stringify(this.formData)); //深拷贝一份原始数据到formRawData
    this.tableDateRefresh();
  }
}
</script>
